// config.js
module.exports = {
  siteURL: "https://citadela.apolocrm.com",
  app: {
    apiKey: "AIzaSyDTVTepISxT4I4Y-J_vrExB9n-N7kHPDlg",
    authDomain: "citadela-awy.firebaseapp.com",
    databaseURL: "https://citadela-awy.firebaseio.com",
    projectId: "citadela-awy",
    storageBucket: "citadela-awy.appspot.com",
    messagingSenderId: "227363334942",
    appId: "1:227363334942:web:56f39da1d9f26126a3770f",
  },
  tokens: [
    "o27sQnK1q6obUn5HptqEPJQ0YMF+9c89bBccaUT6MeCQoCBGINd9CEFKd4cORLH/xqFOSWzM2/P45T0zjnOyRg==",
  ],
  gtm_id: "GTM-M28KKV3",
  statitics_url:
    "https://datastudio.google.com/embed/reporting/1WF3TX06PBEFPijvM46xrVje-vrySRfvX/page/DVWQB",
  report_activities_url:
    "https://datastudio.google.com/embed/reporting/a8ef9566-2574-4ffb-8d7f-2a3937818f8a/page/nYLqB",
  fcm_key:
    "BBbOvzOet_Lm-ksDsoDLhuX8D58pytCZjIKJZoRL2XTazGM7Yao80JFJPgIccuAAoodJJ8aKAY2pqnT4VGKt22Y",
  algolia: {
    id: "FNC2I5F6QG",
    key: "0c5305c989c799347b59a2943ec42df9",
    admin_key: "ae976ffc96df1c201c9f62965f6c8e5e",
  },
  whatsappServerURL: "",
  hideOriginForAdvisors: false,
  allowAllocationSameAgent: false,
  allowAllocationByPortion: false,
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Institución", key: "company", width: 20, type: "string" },
    { header: "Cargo", key: "role", width: 20, type: "string" },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "País", key: "country", width: 20, type: "string" },
    { header: "Institución", key: "company", width: 20, type: "string" },
    { header: "Cargo", key: "role", width: 20, type: "string" },
    { header: "Asunto de contacto", key: "reason", type: "string", width: 20 },
    { header: "Mensaje", key: "comment", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "array" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    {
      header: "¿Aceptó términos y condiciones?",
      key: "terms",
      type: "terms",
      width: 20,
    },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6, md: 4 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) ||
          "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "select",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "select",
      label: "Ciudad",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    rol: {
      type: "text",
      label: "Cargo",
      placeholder: "Cargo",
      flex: { xs: 12, sm: 6 },
    },
    company: {
      type: "text",
      label: "Institución",
      placeholder: "Institución",
      flex: { xs: 12, sm: 6 },
    },
    comment: {
      type: "text",
      label: "Comentario",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
    },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Citadela",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones de Citadela",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact: [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    {
      header: "Correo electrónico",
      key: "contact_email",
      width: 20,
      type: "email",
    },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      show_attr: "full_name",
    },
  },
  saleImportData: [
    { header: "Nombres*", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Dirección", key: "address", width: 20, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    {
      header: "Total de compra*",
      key: "total_paid",
      width: 20,
      type: "number",
    },
    {
      header: "Método de pago (PAGO EXITOSO/PROCESANDO PAGO)",
      key: "payment_method",
      width: 20,
      type: "string",
    },
    {
      header: "Fecha de venta (dd/mm/yyyy)",
      key: "sales_date",
      width: 20,
      type: "date",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    {
      header: "Nombres",
      key: "name",
      type: "string",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Apellidos",
      key: "last_name",
      type: "last_name",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Correo electrónico",
      key: "email",
      type: "email",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Teléfono",
      key: "phone",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Dirección",
      key: "address",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Producto/Cantidad/Precio",
      key: "products",
      type: "products",
      width: 30,
    },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    {
      header: "Método de pago",
      key: "payment_method",
      type: "string",
      width: 20,
    },
    { header: "Encargado", key: "created_uid", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
  ],
  orderFilters: {
    state: { id: "state", name: "Estado", primary: true },
    products_ids: { id: "products_ids", name: "Producto", primary: true },
  },
  ticketFilters: {
    state: { id: "state", name: "Estado", primary: true },
    origin: { id: "origin", name: "Origen", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      item_text: "full_name",
    },
  },
  tableTicket: [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Origen",
      align: "center",
      sortable: false,
      value: "origin",
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "right",
      sortable: false,
      value: "operations",
    },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      searchable: true,
    },
    document_type: {
      type: "select",
      label: "Documento",
      items: ["DNI", "Carnet Extranjero", "RUC"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          !v ||
          (!!v &&
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            )) ||
          "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) ||
          "Teléfono no válido",
      ],
      show_on_detail: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    company: {
      type: "text",
      label: "Institución",
      placeholder: "Institución",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    role: {
      type: "text",
      label: "Cargo",
      placeholder: "Cargo",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    meters_high: {
      type: "autocomplete",
      label: "Metros de altura",
      items: ["5m", "9.40m", "13m"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    services: {
      type: "text",
      label: "Servicios",
      placeholder: "Servicios",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    reason: {
      type: "text",
      label: "Asunto de contacto",
      placeholder: "Asunto de contacto",
      flex: { xs: 12 },
    },
    comment: {
      type: "text",
      label: "Comentario/Mensaje",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
    },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: [
        "Linkedin",
        "Whatsapp",
        "Facebook",
        "Instagram",
        "Web",
        "Llamada",
        "Correo",
        "Sala de ventas",
        "Consulta",
        "Evento",
      ],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
    },
    terms: {
      type: "hidden",
      label: "Términos y condiciones",
      data_type: "boolean",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      data_type: "tags",
      flex: { xs: 12 },
    },
  },
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    {
      header: "Tipo de documento (DNI/carnet extranjería)",
      key: "document_type",
      width: 20,
      type: "string",
    },
    {
      header: "Número de documento",
      key: "document_number",
      width: 20,
      type: "string",
    },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Empresa", key: "company", type: "string", width: 20 },
    { header: "Rol", key: "role", type: "string", width: 20 },
    {
      header: "Metros de altura",
      key: "meters_high",
      type: "string",
      width: 20,
    },
    { header: "Servicios", key: "services", type: "string", width: 20 },
    { header: "Asunto de contacto", key: "reason", type: "string", width: 20 },
    { header: "Mensaje", key: "comment", type: "string", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    { header: "Etiquetas", key: "tags", type: "tags", width: 20 },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  ticketImportData: [
    {
      header: "Nombre del Ticket",
      key: "ticket_name",
      type: "string",
      width: 30,
    },
    {
      header: "Nombres",
      key: "name",
      width: 30,
      type: "string",
      validate: true,
      required: true,
    },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    {
      header: "Tipo de documento (DNI/carnet extranjería)",
      key: "document_type",
      width: 20,
      type: "string",
    },
    {
      header: "Número de documento",
      key: "document_number",
      width: 20,
      type: "string",
    },
    { header: "Empresa", key: "company", type: "string", width: 20 },
    { header: "Rol", key: "role", type: "string", width: 20 },
    {
      header: "Metros de altura",
      key: "meters_high",
      type: "string",
      width: 20,
    },
    { header: "Servicios", key: "services", type: "string", width: 20 },
    { header: "Asunto de contacto", key: "reason", type: "string", width: 20 },
    { header: "Mensaje", key: "comment", width: 20, type: "string" },
    {
      header: "Términos y condiciones (si/no)",
      key: "terms",
      width: 20,
      type: "boolean",
    },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
      validate: true,
    },
    {
      header: "Origen",
      key: "origin",
      width: 20,
      type: "string",
      validate: true,
      required: true,
    },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, type: "string" },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  orderFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: ["required"],
      order: 2,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      order: 3,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      order: 4,
    },
    phone_secondary: {
      type: "text",
      label: "Teléfono convencional",
      placeholder: "Teléfono convencional",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      first_word: "Teléfono convencional: ",
      show_on_landing: true,
      order: 5,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: ["required", "email"],
      order: 6,
      searchable: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      items: ["DNI", "Carnet de extranjería"],
      placeholder: "Seleccione",
      flex: { sm: 6 },
      order: 7,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { sm: 6 },
      order: 7,
      searchable: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [
        "whatsApp",
        "facebook",
        "instagram",
        "twitter",
        "linkedIn",
        "tienda",
      ],
      placeholder: "Seleccione",
      rules: ["required"],
      flex: { xs: 12, sm: 6 },
      order: 9,
    },
  },
  agentTable: [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Equipo",
      align: "center",
      value: "team",
    },
    {
      text: "Proyectos",
      align: "center",
      value: "projects",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "active",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: "#5aa1e3",
        secondary: "#09e35e",
        accent: "#4dbb6d",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        primary: "#5aa1e3",
        secondary: "#09e35e",
        accent: "#4dbb6d",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
};
